import { LazyMotion } from 'framer-motion';
import React from 'react';
import { LiveChatLoaderProvider } from 'react-live-chat-loader';
import { RecoilRoot } from 'recoil';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaqSearchProvider } from './src/contexts/faqSearchContext';
import { PricingToggleProvider } from './src/contexts/pricingToggleContext';
import { ModalProvider } from './src/molecules/Modal/context/ModalContainerContext';
import './src/styles/layout.scss';
import { DisclaimerProvider } from './src/contexts/DisclaimersContext';
import './static/fonts/fonts.scss';

const features = () =>
  import('./src/utils/motionFeatures').then(res => res.default);

export const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps: { location },
}) => {
  // Prevent scrolling to the top when navigating to the same page
  if (prevRouterProps?.location?.pathname === location.pathname) {
    return false;
  }

  return location.state?.scrollY ? [0, location.state.scrollY] : true;
};

export const onRouteUpdate = ({ location }) => {
  // Manage scroll position
  if (location.state?.scrollY) {
    window.setTimeout(() => {
      const targetElement = document.getElementById(
        location.hash?.replace('#', '')
      );

      // Prioritize hash if provided
      window.scrollTo({
        left: 0,
        top: targetElement ? targetElement.offsetTop : location.state.scrollY,
        behavior: 'smooth',
      });
    }, 0);
  }

  // Populate locations history
  window.locations = window.locations || [document.referrer];
  if (window.locations[locations.length - 1] !== window.location.href) {
    window.locations.push(window.location.href);
  }
  window.previousPath = window.locations[locations.length - 2];
};

export const wrapRootElement = ({ element }) => (
  <RecoilRoot>
    <LazyMotion features={features} strict>
      <DisclaimerProvider>
        <FaqSearchProvider>
          <PricingToggleProvider>
            <ModalProvider>
              <LiveChatLoaderProvider
                provider="intercom"
                providerKey="uq2tacb0"
              >
                {element}
              </LiveChatLoaderProvider>
            </ModalProvider>
          </PricingToggleProvider>
        </FaqSearchProvider>
      </DisclaimerProvider>
    </LazyMotion>
  </RecoilRoot>
);
